<!-- Features Start -->
<section class="section" id="features">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Choisir <span class="text-warning">ExpertNow</span></h3>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">01.</h5>
                    <p class="text-muted mb-3 f-15">ExpertNow est un cabinet de conseil à taille humaine avec un management de
                        proximité, une culture et des valeurs qui inscrivent nos collaborateurs au cœur de son
                        développement. La relation avec nos consultants est un des leviers de notre proposition de
                        valeur nous permettant d’instaurer une relation durable, tout en les accompagnant à devenir des
                        experts</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5 big-screen ">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">02.</h5>
                    <p class="text-muted mb-3 f-15">Grâce à nos consultants et experts, nous offrons à nos clients, des
                        services d’excellence dans le secteur du numérique. Nos secteurs d’intervention sont variés avec une expertise qui reste dominante
                        dans la sécurité informatique et la qualité logiciel.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0"
                style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5 small-screen ">
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0"
                style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>

            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">02.</h5>
                    <p class="text-muted mb-3 f-15">Grâce à nos consultants et experts, nous offrons à nos clients, des
                        services d’excellence dans le secteur du numérique. Nos secteurs d’intervention sont variés avec une expertise qui reste dominante
                        dans la sécurité informatique et la qualité logiciel.</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-3.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">03.</h5>
                    <p class="text-muted mb-3 f-15">Notre recherche de l’excellence nous conduit à nous entourer des
                        meilleurs talents, à investir sur la formation (en particulier les formations certifiantes), à
                        favoriser les activités de veilles (tant techniques que méthodologiques) et placer au centre de
                        tout, la compréhension des métiers et enjeux de nos Clients. </p>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- Features End -->

<!-- Counter Start -->
<!-- 
<section class="section counter-bg" style="background: url(assets/images/counter-bg-1.jpg) center center">
    <div class="bg-overlay op-75"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mb-5">
                    <h2 class="font-weight-normal text-white mb-2">Nous avons terminé</h2>
                    <h5 class="font-weight-normal text-white-70">Plus de 10+ travaux réussis
                    </h5>
                </div>
            </div>
        </div>
        <div class="row" id="counter">
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-like2 counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="6" [from]="0" [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">PROJET RÉUSSI</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-light counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="3" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Idées de projets</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-smile counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="5" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Clients satisfaits</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-star counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="3" [from]="0"
                        [duration]="1"></span></h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Gagner un prix</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Counter End -->
