<!-- About us Start -->
<section class="section bg-light" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Choisir <span class="text-warning">ExpertNow</span></h3>
                    <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p>
                </div>
            </div>
        </div>

        <div class="row">
      
            <div>
                <div class="row">
                    <div class="col-md-4">
                        <h6 class="text-dark font-weight-light f-20 mb-3">01.</h6>
                        <p class="text-muted font-weight-light">ExpertNow est un cabinet de conseil à taille humaine avec un management de proximité, une culture et des valeurs qui inscrivent nos collaborateurs au cœur de son développement. La relation avec nos consultants est un des leviers de notre proposition de valeur nous permettant d’instaurer une relation durable, tout en les accompagnant à devenir des experts</p>
                    </div>
                    <div class="col-md-4">
                        <h6 class="text-dark font-weight-light f-20 mb-3">02.</h6>
                        <p class="text-muted font-weight-light">Grâce à nos consultants et experts,  nous offrons  à nos clients, des services d’excellence dans le secteur du numérique (conseil métier/MOA, business intelligence, data science,). Nos secteurs d’intervention sont variés avec une expertise qui reste dominante dans la sécurité informatique et la qualité logiciel.</p>
                    </div>
                    <div class="col-md-4">
                        <h6 class="text-dark font-weight-light f-20 mb-3">03.</h6>
                        <p class="text-muted font-weight-light">Notre recherche de l’excellence nous conduit à nous entourer des meilleurs talents, à investir sur la formation (en particulier les formations certifiantes), à favoriser les activités de veilles (tant techniques que méthodologiques) et placer au centre de tout, la compréhension des métiers et enjeux de nos Clients. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About us End -->
